@mixin contact-banner-title {
  font-family: $theme-font-bold;
  @include font-uppercase-bold;

  position: absolute;
  z-index: 1;
  top: 3.5rem;
  padding-right: 1.5rem;
  font-size: 3rem;
  line-height: 3.5rem;
  text-shadow: 0 0 1px white;

  @include media-breakpoint-up(sm) {
    font-size: 3rem;
  }

  @include media-breakpoint-up(md) {
    font-size: 3.5rem;
    line-height: 4rem;
    top: 2rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 3rem;
  }
}
