$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/pkag/';
@import 'styles/helpers/global_variables';

.root {
  @include content-block;
  @include floating-text;

  //margin-bottom: 1rem;
  @include display-linebreak;
}
