@mixin image-block-font {
  @include readability-text-shadow;
  @include font-bold;

  // font size
  font-size: 3.5rem;
  @include media-breakpoint-up(md) {
    font-size: 4.5rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 6rem;
  }
}
