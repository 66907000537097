$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/pkag/';
@import 'styles/helpers/global_variables';

$fontColor: $tertiary;

.root {
  @include content-block;

  .leadText {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .gtcInfo {
    font-size: 0.7rem;
    font-style: italic;
  }

  .input {
    background-color: transparent;
    color: $fontColor;
    box-shadow: 0 0 5px -2px rgb(255 255 255 / 60%);

    &:-webkit-autofill {
      background-color: inherit;
      color: inherit;
    }

    &.select {
      & option {
        text-transform: uppercase;

        &:first-child {
          display: none;
        }
      }
    }

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      text-transform: uppercase;
      letter-spacing: 0.04rem;
      color: $fontColor;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      text-transform: uppercase;
      letter-spacing: 0.04rem;
      color: $fontColor;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      text-transform: uppercase;
      letter-spacing: 0.04rem;
      color: $fontColor;
    }
  }

  .requestCallbackWrap {
    margin-bottom: 1rem;
  }

  .submitWrap {
    .arrow {
      padding-top: 1.3rem;
      color: $font-color-default;
    }
    button {
      color: $white;
    }
  }
}
