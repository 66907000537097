$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/pkag/';
@import 'styles/helpers/global_variables';

$circleSize: 80px;

.root {
  position: absolute;
  z-index: 1;
  top: -15px;
  right: 15px;
  font-size: 14px;
  line-height: 1.1rem;
  background: #eab945;
  height: $circleSize;
  border-radius: $circleSize;
  width: $circleSize;
  padding: 0 !important;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  color: $black;
  background: $secondary;

  @include teaser-flag;

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .text {
    }
  }
}
