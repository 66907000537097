$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/pkag/';
@import 'styles/helpers/global_variables';

.root {
  @include content-block;
  @include floating-text;
  padding-right: 5rem;

  ul {
    @include branded-list;
  }
}
