$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/pkag/';
@import 'styles/helpers/global_variables';

.root {
  cursor: pointer;
  color: $filter-lead-form-submit-btn-color;
  margin-top: $filter-lead-form-submit-btn-margin-top;
  text-decoration: none;
  font-size: $filter-lead-form-submit-btn-font-size;
  line-height: 5.2rem;
  font-family: $filter-lead-form-submit-btn-font-family;
  @include font-bold;
  transition: all $default-animation-speed ease-in-out;

  &:hover {
    color: $filter-lead-form-submit-btn-hover-color;
  }

  &:focus-visible {
    outline: none;
  }
}
