$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/pkag/';
@import 'styles/helpers/global_variables';

.root {
  position: relative;
  height: 100%;
}

.embla {
  height: 100%;
  overflow: hidden;
}
.embla__container {
  height: 100%;
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
}

.img {
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);

  height: 100%;
  width: 100%;
}

.embla__dots {
  @include slider-dots-wrap;
}
