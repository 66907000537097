@mixin slider-dots-wrap {
  display: flex;
  list-style: none;
  justify-content: center;

  &.bottom {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: -54px;
  }

  &.below {
    margin-top: 15px;
  }
}

@mixin slider-dot-button {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  margin-top: 3px;
  margin-right: 3px;
  margin-left: 3px;
  display: flex;
  align-items: center;
  opacity: 0.4;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  width: 69px;
  height: 48px;
  transition: all $default-animation-speed ease-in-out;

  &:hover {
    //transform: rotate(90deg);
    opacity: 0.7;
  }

  &.isSelected {
    //transform: rotate(180deg);
    opacity: 1;
  }

  &.light {
    background-image: url($cloudinaryThemeCloudBaseUrl + 'images/icons/waves/gallery-wave-light-1.png');
    filter: drop-shadow(0 0 1px #bbbbbb);
  }
  &.dark {
    background-image: url($cloudinaryThemeCloudBaseUrl + 'images/icons/waves/gallery-wave-dark-1.png');
  }

  &:nth-child(even) {
    margin-top: 0;
    &.light {
      background-image: url($cloudinaryThemeCloudBaseUrl + 'images/icons/waves/gallery-wave-light-2.png');
    }
    &.dark {
      background-image: url($cloudinaryThemeCloudBaseUrl + 'images/icons/waves/gallery-wave-dark-2.png');
    }
  }
}
