$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/pkag/';
@import 'styles/helpers/global_variables';

.root {
  @include content-block;
  @include floating-text;

  margin: 3rem 0;

  .cardTitle {
    color: $gray-800;
    margin-bottom: 0.2rem;
  }

  .jobTitle {
    color: $gray-600;
    margin-bottom: 1.5rem;
  }

  .cardBody {
    @include card-body;
    @include display-linebreak;
    //padding: 1.3rem 2rem 1.5rem 2rem;
    //@include media-breakpoint-up(md) {
    //  padding: 1.3rem 3rem 1.5rem 3rem;
    //}

    .description {
      margin-bottom: 2.5rem;
    }

    .contactInfo {
      position: absolute;
      bottom: 1rem;
      text-decoration: none;
      font-size: 0.9rem;
      color: $gray-800;
      display: flex;
      align-items: center;

      > * {
        flex: 0 0 auto;
      }

      &:hover {
        color: $secondary;
      }
    }
  }
}
